<template>
  <div class="aboutComp">
    <div id="threePush" ref="threePush"></div>
    <!--
      <div class="bgChangeContainer">
        <div class="bgChange" ref="bgChange">背景切り替え {{ nowRoute }}</div>
      </div>
    -->
  </div>
</template>

<style>
.bgChangeContainer {
  width: 100%;
  display: block;
  height: 20px;
  margin-top:10px;
}
.bgChange {
  width: 120px;
  display: inline-block;
  background-color: #41b883;
  color: #000;
  font-weight: 600;
}
#threePush {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.aboutComp {
  height: 90%;
  width: 100%;
  position: absolute;
}
</style>

<script>
import * as THREE from 'three'
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js'

export default {
  name: 'about',
  props: [
    'nowRoute',
    'refApp',
    'appWidth',
    'appHeight',
    'initAnim',
    'refBg1',
    'refBg3'
  ],
  mounted () {
    this.canvasAnim()
  },
  methods: {
    onclick: function () {
      if (this.refApp) {
        if (this.nowRoute === '/') {
          this.refApp.style.backgroundColor = '#000'
        } else if (this.nowRoute === '/about') {
          this.refApp.style.backgroundColor = '#000'
        }
      }
    },
    canvasAnim: function () {
      let camera, scene, renderer
      const threePush = this.$refs.threePush
      // const bgChange = this.$refs.bgChange
      const cw = parseInt(this.appWidth)
      const ch = parseInt(this.appHeight)

      // const urlSet = ["url('static/1.jpg')", "url('static/2.jpg')", "url('static/3.jpg')", "url('static/4.jpg')", "url('static/5.jpg')", "url('static/6.jpg')", "url('static/7.jpg')", "url('static/8.jpg')", "url('static/9.jpg')", "url('static/10.jpg')", "url('static/11.jpg')", "url('static/12.jpg')", "url('static/13.jpg')"]

      const clock = new THREE.Clock()
      let mixer

      function init () {
        scene = new THREE.Scene()
        /*
      const gltfloader = new GLTFLoader()
      const url = 'static/myCharaGLTF01.glb'

      gltfloader.load(url, (data) => {
        const gltf = data
        const object = gltf.scene
        const animations = gltf.animations

        if (animations && animations.length) {
          // let i
          mixer = new THREE.AnimationMixer(object)
          // for (i = 0; i < animations.length; i++) {
          //   mixer.clipAction(animations[i]).play()
          // }
          mixer.clipAction(animations[0]).play()
        }

        for (const matt in object.children[1].children) {
          if (object.children[1].children[matt].material !== undefined) {
            const maps = object.children[1].children[matt].material.map
            const skinnings = object.children[1].children[matt].material.skinning
            object.children[1].children[matt].material = new THREE.MeshToonMaterial()
            object.children[1].children[matt].material.map = maps
            object.children[1].children[matt].material.skinning = skinnings
          }
        }
        object.position.set(0, -7, 0)

        scene.add(object)
        // const light = new THREE.AmbientLight(0xFFFFFF, 1)
        const pointLight = new THREE.PointLight(0xFFFFFF, 1.3, 0, 1)
        pointLight.position.set(10, 30, 50)
        // scene.add(light)
        scene.add(pointLight)
      })
      */

        const fbxloader = new FBXLoader()
        const url2 = 'static/newTestMyChara01.fbx'
        fbxloader.load(url2, function (object) {
          mixer = new THREE.AnimationMixer(object)

          object.animations[0].duration = 0.65

          const action = mixer.clipAction(object.animations[0])
          action.play()

          object.traverse(function (child) {
            if (child.isMesh) {
              child.castShadow = true
              child.receiveShadow = true
            }
          })
          object.position.set(0, -7, 0)

          const light = new THREE.AmbientLight(0xFFFFFF, 1)
          const pointLight = new THREE.PointLight(0xFFFFFF, 1.3, 0, 1)
          pointLight.position.set(10, 30, 50)

          scene.add(light)
          scene.add(pointLight)
          scene.add(object)
        })

        renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
        // renderer.setClearColor(0x000000, 0)
        renderer.setSize(400, 400)

        camera = new THREE.PerspectiveCamera(15, 400 / 400, 1, 5000)
        camera.position.y = 10
        camera.position.z = 70

        const controls = new OrbitControls(camera, renderer.domElement)
        controls.userPan = false
        controls.enablePan = false
        controls.userPanSpeed = 0.0
        controls.maxDistance = 500.0
        controls.minDistance = 1.0
        controls.maxAzimuthAngle = Math.PI * 0.3
        controls.maxPolarAngle = Math.PI * 0.495
        controls.enableZoom = false
        controls.autoRotate = true
        controls.autoRotateSpeed = 1.0
        renderer.domElement.style.outline = 'none'

        threePush.appendChild(renderer.domElement)
        // threePush.childNodes[0].style.backgroundImage = "url('static/6.jpg')"
        // threePush.childNodes[0].style.backgroundPosition = '-61px 0px'

        // const i = 0

        // bgChange.addEventListener('click', function () {
        //   threePush.childNodes[0].style.backgroundImage = urlSet[i]
        //   i = i + 1
        //   if (i === 12) i = 0
        // })

        const animate = () => {
          renderer.render(scene, camera)
          controls.update()
          if (mixer) {
            mixer.update(clock.getDelta())
          }
          requestAnimationFrame(animate)
        }

        animate()
      }

      init(cw, ch)

    // const animate = () => {
    //   renderer.render(scene, camera)
    //   controls.update()
    //   if (mixer) {
    //     mixer.update(clock.getDalta())
    //   }
    //   requestAnimationFrame(animate)
    // }
    }
  },
  created: function () {
    if (this.refApp) {
      if (this.nowRoute === '/') {
        this.refApp.style.backgroundColor = '#000'
      } else if (this.nowRoute === '/about') {
        this.refApp.style.backgroundColor = '#000'
      }
    }
  },
  watch: {
    nowRoute: function () {
      if (this.refApp) {
        if (this.nowRoute === '/') {
          this.refApp.style.backgroundColor = '#000'
          this.refApp.style.transition = '0.3s'
          this.refBg1.style.backgroundImage = "url('/static/12.jpg')"
          if (this.refBg3.classList.contains('slide-bg') === true) {
            this.refBg3.classList.remove('slide-bg')
          }
          if (this.$refs.threePush) {
            this.$refs.threePush.style.transitionDuration = '0.5s'
            this.$refs.threePush.style.transitionDelay = '0.5s'
            this.$refs.threePush.style.justifyContent = 'center'
            this.$refs.threePush.style.alignItems = 'center'
          }
        } else if (this.nowRoute === '/about') {
          this.refApp.style.backgroundColor = '#000'
          this.refApp.style.transition = '0.3s'
          this.refBg1.style.backgroundImage = "url('/static/11.jpg')"
          this.refBg3.style.backgroundImage = "url('/static/12.jpg')"
          this.refBg3.classList.add('slide-bg')
          if (this.$refs.threePush) {
            this.$refs.threePush.style.transitionDuration = '0.5s'
            this.$refs.threePush.style.transitionDelay = '0.5s'
            this.$refs.threePush.style.justifyContent = 'flex-start'
            this.$refs.threePush.style.alignItems = 'flex-end'
          }
        }
      }
    },
    appWidth: function () {
      // this.canvasAnim()
    }
  }
}
</script>
