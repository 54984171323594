<template>
  <div id="app" ref="app">
    <header>
      <ul>
        <li
          ref='homeLink'
          v-on:click="routeCheck"
        >
          <router-link to="/">Home</router-link>
        </li>
        <li
          ref='aboutLink'
          v-on:click="routeCheck"
        >
          <router-link to="/about">Photos</router-link>
        </li>
      </ul>
    </header>
    <div class="bg-container">
      <div ref="bg1" class="bg-1" />
      <div ref="bg3" class="bg-3" />
      <div class="bg-2" />
    <AboutComp
      :now-route="nowPath"
      :ref-app="appParam"
      :ref-bg1="bg1Param"
      :ref-bg3="bg3Param"
      :app-width="appSizeW"
      :app-height="appSizeH"
      msg="Home"/>
      <div class="scrollArea">
        <transition mode="out-in">
          <router-view/>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import AboutComp from '@/components/aboutComp.vue'

export default {
  name: 'App',
  components: {
    AboutComp
  },
  methods: {
    routeCheck: function () {
      if (this.oldPath !== this.$route.path) {
        this.oldPath = this.$route.path
      }
      return this.oldPath
    },
    setAppWidth: function () {
      this.appSizeW = this.$refs.app.offsetWidth
    }
  },
  data: function () {
    return {
      appParam: null,
      appSizeW: 0,
      appSizeH: 0,
      bg1Param: null,
      bg3Param: null
    }
  },
  computed: {
    nowPath: function () {
      return this.$route.path
    }
  },
  mounted () {
    const homeL = this.$refs.homeLink
    const aboutL = this.$refs.aboutLink
    const routeC = this.routeCheck()
    if (routeC === '/') {
      aboutL.style.backgroundColor = '#aaa'
      homeL.style.backgroundColor = '#000'
      this.$refs.app.style.backgroundColor = '#000'
    } else if (routeC === '/about') {
      aboutL.style.backgroundColor = '#000'
      homeL.style.backgroundColor = '#aaa'
    }
    this.appParam = this.$refs.app
    this.bg1Param = this.$refs.bg1
    this.bg3Param = this.$refs.bg3
    this.appSizeW = this.$refs.app.offsetWidth
    this.appSizeH = this.$refs.app.offsetHeight
  },
  watch: {
    nowPath: function (e) {
      const homeL = this.$refs.homeLink
      const aboutL = this.$refs.aboutLink
      if (e === '/') {
        aboutL.style.backgroundColor = '#aaa'
        homeL.style.backgroundColor = '#000'
      } else if (e === '/about') {
        aboutL.style.backgroundColor = '#000'
        homeL.style.backgroundColor = '#aaa'
      }
    }

  }
}
</script>

<style scoped lang="scss">
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    height: 100%;
  }
  header {
    background : #777;
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      list-style: none;
      li {
        padding: 10px;
        a {
          color: #fff;
        }
      }
    }
  }
  .bg-container {
    position: relative;
    top:0;
    left:0;
    width: 100%;
    height: 95%;
  }
  .bg-1{
    position: absolute;
    top: 0;
    left: 25%;
    width: 50%;
    height: 100%;
    background-image: url('/static/12.jpg');
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .bg-2{
    position: absolute;
    top: 0;
    left: 25%;
    width: 50%;
    height: 100%;
    background-image: url('/static/maru_bg.png');
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .bg-3{
    position: absolute;
    top: 0;
    left: 25%;
    width: 50%;
    height: 100%;
    background-image: url('/static/12.jpg');
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .scrollArea {
    width: 100%;
    height: 100%;
    position: fixed;
    overflow-y: scroll;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .v-enter-active, .v-leave-active {
    transition: opacity .2s;
  }
  .v-enter, .v-leave-to {
    opacity: 0;
  }
  .slide-bg {
    animation: transit 1s;
    animation-fill-mode: forwards;
  }
  @keyframes transit {
    from {
        background-position: 0 0;
        opacity: 1;
    }
    to {
        background-position: -1000px 0;
        opacity: 0;
    }
  }
</style>
