<template>
  <div class="home">
    <HelloWorld msg="Home"/>
    <div class="infomagion-bg">
      <div class="infomation-title">使用ツール/フレームワーク</div>
      <div class="infomation">vue.js</div>
      <div class="infomation">three.js</div>
      <div class="infomation">photoshop</div>
      <div class="infomation">illustlator</div>
      <div class="infomation">MAYA</div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  }
}
</script>

<style>
  .infomagion-bg{
    position: absolute;
    top: 55%;
    left: 20%;
    text-align: left;
    font-size: 20px;
  }
  .infomation-title {
    color: #fff;
    font-weight: bold;
  }
  .infomation {
    color: #fff;
    font-weight: bold;
    margin-left: 10%;
  }
</style>
